import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index.js';
import VueAnalytics from 'vue-analytics'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'root',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Register.vue')
    },
    {
        path: '/thankyou',
        name: 'thankyou',
        component: () => import('@/views/Thankyou.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/Logout.vue')
    },
    {
        path: '/password_request',
        name: 'password_request',
        component: () => import('@/views/PasswordRequest.vue')
    },
    {
        path: '/password_reset/:token',
        name: 'password_reset',
        component: () => import('@/views/PasswordReset.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/images',
        name: 'images',
        component: () => import('@/views/Images.vue')
    },
    {
        path: '/branding',
        name: 'branding',
        component: () => import('@/views/Branding.vue')
    },
    {
        path: '/upload',
        name: 'upload',
        component: () => import('@/views/Upload.vue')
    },
    {
        path: '/upload_edit',
        name: 'upload_edit',
        component: () => import('@/views/UploadEdit.vue')
    },
    {
        path: '/lightboxes',
        name: 'lightboxes',
        component: () => import('@/views/Lightboxes.vue')
    },
    {
        path: '/lightbox',
        name: 'lightbox',
        component: () => import('@/views/Lightbox.vue')
    },
    {
        path: '/lightbox_ext/:stmp',
        name: 'lightbox_ext',
        component: () => import('@/views/LightboxExt.vue')
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/Users.vue')
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('@/views/User.vue')
    },
    {
        path: '/brands',
        name: 'brands',
        component: () => import('@/views/Brands.vue')
    },
    {
        path: '/remarks',
        name: 'remarks',
        component: () => import('@/views/Remarks.vue')
    },
    {
        path: '/groups',
        name: 'groups',
        component: () => import('@/views/Groups.vue')
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('@/views/Faq.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/Contact.vue')
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('@/views/Terms.vue')
    },
    {
        path: '*',
        name: 'whatever',
        component: () => import('@/views/Login.vue')
    },
]

const router = new VueRouter({
    routes
})

if (store.state.getGoogleAnalyticsId) {
    Vue.use(VueAnalytics, {
        id: store.state.getGoogleAnalyticsId,
        router
    })
}

router.beforeEach((to, from, next) => {
    document.title = 'Brand asset library | MNP / Suntory';
    next();
});

export default router
