import axios from 'axios'

let apiClient = axios.create({
    // baseURL: 'http://127.0.0.1:8000/api',
    baseURL: '/api',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

export default apiClient
