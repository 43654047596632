<template>
    <v-app>
        <v-app-bar app dark height="100" :color="base.barcolor">
            <v-toolbar-title>
                <v-img :src="'assets/' + base.logo" @click="goHome"></v-img>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <span class="title">
                {{ base.header }}
            </span>

            <v-spacer></v-spacer>

            <div v-if="base.id">
                <div v-if="isLoggedIn">
                    <v-btn id="homeLink" text to="/home">{{ $t('home') }}</v-btn>
                    <v-btn id="imagesLink" text to="/images">{{ $t('images') }}</v-btn>

                    <span v-if="settingBranding">
                        <v-btn id="brandingLink" text to="/branding">{{ $t('branding') }}</v-btn>
                    </span>

                    <v-btn id="lightboxesLink" text to="/lightboxes">{{ $t('lightboxes') }}</v-btn>

                    <span v-if="isAdmin">
                        <v-btn id="usersLink" text to="/users">{{ $t('users') }}</v-btn>
                        <v-btn id="brandsLink" text to="/brands">{{ $t('brands') }}</v-btn>
                        <v-btn id="remarksLink" text to="/remarks">{{ $t('remarks') }}</v-btn>
                        <v-btn id="groupsLink" text to="/groups">{{ $t('groups') }}</v-btn>

                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text v-bind="attrs" v-on="on">
                                    {{$t('upload')}}
                                    <v-icon right>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-btn id="uploadLinkFiles" text to="/upload">{{ $t('upload_files') }}</v-btn>
                                </v-list-item>

                                <v-list-item>
                                    <v-btn id="uploadLinkEdit" text to="/upload_edit">{{ $t('upload_edit') }}</v-btn>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </span>

                    <span v-else>
                        <v-btn id="userLink" text to="/user">{{ $t('my_account') }}</v-btn>
                        <v-btn id="faqlink" text to="/faq">{{ $t('faq') }}</v-btn>
                        <v-btn id="contactlink" text to="/contact">{{ $t('contact') }}</v-btn>
                    </span>

                    <v-btn id="logoutLink" text to="/logout">{{ $t('logout') }}</v-btn>
                </div>

                <div v-else>
                    <v-btn id="registerLink" text to="/register">{{ $t('register') }}</v-btn>
                    <v-btn id="loginLink" text to="/login">{{ $t('login') }}</v-btn>
                </div>
            </div>
        </v-app-bar>

        <v-content>
            <router-view></router-view>
        </v-content>

        <v-footer :color="base.barcolor">
            <v-row no-gutters>
                <v-col class="py-4 body-2 text-center white--text">
                    &copy; {{ new Date().getFullYear() }} — {{ base.name }}
                    <span class="ml-3 mr-3">|</span>
                    All rights reserved
                    <span class="ml-3 mr-3">|</span>
                    <router-link class="footerlink" to="/terms">{{ $t('terms') }}</router-link>
                    <span class="ml-3 mr-3">|</span>
                    <a class="footerlink" :href="websiteString">{{ base.website }}</a>
                    <span class="ml-3 mr-3">|</span>
                    <a class="footerlink" :href="emailString">{{ base.email }}</a>
                    <span class="ml-3 mr-3">|</span>
                    <a class="footerlink" :href="telephoneString">{{ base.telephone }}</a>
                    <span class="ml-3 mr-3">|</span>
                    <a class="footerlink" :href="locationString">
                    {{ base.address }}, {{ base.postcode }} {{ base.city }}, {{ base.country }}
                    </a>
                </v-col>
            </v-row>
        </v-footer>
    </v-app>
</template>

<script>
    import baseService from '@/services/BaseService.js'
    import {mapState} from 'vuex'

    export default {
        name: 'App',

        data() {
            return {
                settingBranding: false,
            }
        },

        computed: {
            ...mapState(['base', 'isLoggedIn', 'isAdmin']),

            websiteString() {
                return "http://" + this.base.website
            },

            emailString() {
                return "mailto:" + this.base.email
            },

            telephoneString() {
                return "tel:" + this.base.telephone
            },

            locationString() {
                // Google maps
                return "https://goo.gl/maps/Esj8its8dp3txNKt5"
            },
        },

        created() {
            baseService.getBase()
                .then(response => {
                    // Update store
                    this.$store.dispatch('setBase', response.data)

                    // Set language
                    this.$i18n.locale = response.data.language

                    // Setting branding
                    this.settingBranding = response.data.setting_branding
                    if (this.settingBranding == "Y") {
                        this.settingBranding = true
                    }
                })
                .catch(() => {
                    // Set error in store
                    this.$store.dispatch('setBaseError')
                })
        },

        methods: {
            goHome() {
                this.$router.push('/home')
            }
        }
    };
</script>

<style scoped>
    .footerlink {
        color: white;
        text-decoration: none;
    }
</style>
