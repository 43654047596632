import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        base: null,
        user: null,
        lightbox: null,
        stmp: null,

        isError: false,
        isLoggedIn: false,
        isVisitor: false,
        isIntern: false,
        isAdmin: false,
    },

    mutations: {
        login(state, data) {
            state.user = data

            state.isLoggedIn = true

            if (data.role == "Visitor") {
                state.isVisitor = true
            }
            if (data.role == "Intern") {
                state.isIntern = true
            }
            if (data.role == "Admin") {
                state.isAdmin = true
            }
        },

        logout(state) {
            state.user = null
            state.lightbox = null

            state.isLoggedIn = false
            state.isVisitor = false
            state.isIntern = false
            state.isAdmin = false
        },

        setBase(state, data) {
            state.base = data
        },

        setBaseError(state) {
            state.base.barcolor = 'error'
            state.isError = true
        },

        setLightbox(state, data) {
            state.lightbox = data
        },

        setStmp(state, data) {
            state.stmp = data
        },
    },

    actions: {
        login({commit}, data) {
            commit('login', data)
        },

        logout({commit}) {
            commit('logout')
        },

        setBase({commit}, data) {
            commit('setBase', data)
        },

        setBaseError({commit}) {
            commit('setBaseError')
        },

        setLightbox({commit}, data) {
            commit('setLightbox', data)
        },

        setStmp({commit}, data) {
            commit('setStmp', data)
        },
    },

    getters: {
        getName(state) {
            return state.base.name
        },
        getRoles() {
            return ['Admin', 'Intern', 'Visitor']
        },
        getGender(state) {
            if (state.base.language == 'en') {
                return ['Mr.', 'Mrs.']
            } else {
                return ['Dhr', 'Mevr']
            }
        },
        getUserStatus(state) {
            let userStatus = ''
            if (state.base.language == 'en') {
                userStatus = state.base.user_status_en.split(',')
            } else {
                userStatus = state.base.user_status_nl.split(',')
            }
            return userStatus
        },
        getCompanyTypes(state) {
            let companyTypes = ''
            if (state.base.language == 'en') {
                companyTypes = state.base.company_type_en.split(',')
            } else {
                companyTypes = state.base.company_type_nl.split(',')
            }
            return companyTypes
        },
        getMotivation(state) {
            let motivation = ''
            if (state.base.language == 'en') {
                motivation = state.base.motivation_en.split(',')
            } else {
                motivation = state.base.motivation_nl.split(',')
            }
            return motivation
        },
        getShots(state) {
            let shots = ''
            if (state.base.language == 'en') {
                shots = state.base.shot_en.split(',')
            } else {
                shots = state.base.shot_nl.split(',')
            }
            return shots
        },
        getPublished(state) {
            let published = ''
            if (state.base.language == 'en') {
                published = state.base.published_en.split(',')
            } else {
                published = state.base.published_nl.split(',')
            }
            return published
        },
        getCategories(state) {
            let categories = ''
            if (state.base.language == 'en') {
                categories = state.base.category_en.split(',')
            } else {
                categories = state.base.category_nl.split(',')
            }
            return categories
        },
        getRemark(state) {
            let remark = ''
            if (state.base.language == 'en') {
                remark = state.base.remark_en.split(',')
            } else {
                remark = state.base.remark_nl.split(',')
            }
            return remark
        },
        getPriority(state) {
            let priority = ''
            if (state.base.language == 'en') {
                priority = state.base.priority_en.split(',')
            } else {
                priority = state.base.priority_nl.split(',')
            }
            return priority
        },
        getActive(state) {
            let active = ''
            if (state.base.language == 'en') {
                active = state.base.active_en.split(',')
            } else {
                active = state.base.active_nl.split(',')
            }
            return active
        },
        getDocumentGroups(state) {
            let documentGroups = ''
            if (state.base.language == 'en') {
                documentGroups = state.base.document_group_en.split(',')
            } else {
                documentGroups = state.base.document_group_nl.split(',')
            }
            return documentGroups
        },
        getDocumentGroupsBranding(state) {
            let documentGroupsBranding = ''
            if (state.base.language == 'en') {
                documentGroupsBranding = state.base.document_group_branding_en.split(',')
            } else {
                documentGroupsBranding = state.base.document_group_branding_nl.split(',')
            }
            return documentGroupsBranding
        },
        getGoogleAnalyticsId(state) {
            return state.base.google_analytics_id
        },
        getBatchSize(state) {
            if (!state.base.batch_size) {
                // Default
                return 50
            }
            else {
                return parseInt(state.base.batch_size)
            }
        },
    },

    modules: {}
})
